var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('div',{staticClass:"custom-search d-flex"},[_c('div',{staticClass:"d-flex mr-auto"},[_c('div',{staticStyle:{"float":"left"}},[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0)'),expression:"'rgba(255, 255, 255, 0)'",modifiers:{"400":true}}],attrs:{"id":"dropdown-7","text":"Actions","variant":"secondary"}},[_c('b-dropdown-item',[_vm._v("Select action:")]),_c('b-dropdown-item',{attrs:{"disabled":!_vm.activate},on:{"click":function($event){return _vm.save(true)}}},[_c('feather-icon',{staticClass:"text-success mr-right-10",attrs:{"icon":"CheckIcon"}}),_vm._v("Activate ")],1),_c('b-dropdown-item',{attrs:{"disabled":!_vm.deactivate},on:{"click":function($event){return _vm.save(false)}}},[_c('feather-icon',{staticClass:"text-danger mr-right-10",attrs:{"icon":"XIcon"}}),_vm._v("Deactivate ")],1)],1)],1)]),_c('b-form-group',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),(_vm.rows.length > 0)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
                enabled: true,
                externalQuery: _vm.searchTerm,
            },"select-options":{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
                enabled: true,
                perPage: 25,
            }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'is_active')?_c('span',[(props.row.is_active === true)?_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.is_active)}},[_vm._v(" Active ")]):_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.is_active)}},[_vm._v(" Inactive ")])],1):(props.column.field === 'groups')?_c('span',_vm._l((props.row.groups),function(item,index){return _c('span',{key:item.id},[_vm._v(" "+_vm._s(item.name)),(
                                props.row.groups.length > 1 &&
                                index < props.row.groups.length - 1
                            )?_c('span',[_vm._v(",")]):_vm._e()])}),0):(props.column.field === 'full_name')?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":"Click to edit user permission!"},on:{"click":function($event){return _vm.$router.push(
                            '/settings/permissions/' + props.row.id
                        )}}},[_c('b-avatar',{staticClass:"mr-right-10",attrs:{"size":"36px","src":props.row.employee.avatar}}),_vm._v(" "+_vm._s(props.row.full_name)+" ")],1):(props.column.field === 'is_staff')?_c('span',[(props.row.is_staff === 'true')?_c('span',[_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" Yes ")])],1):_c('span',[_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" No ")])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.currentPage,"total-rows":_vm.total,"per-page":25,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":_vm.fetchRows},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,208396724)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }